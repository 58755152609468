.skipToContent {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1;
  padding: 0.5rem 1.5rem;
  background-color: #bbb;
  border-radius: 0 0.5rem 0.5rem 0;
  transform: translateX(-100%);
}

.skipToContent:focus {
  outline: none;
  background: #ccc;
  transform: translateY(0);
}
